// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
   Typography,

  List, ListItem
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import PrivacyPolicyController, {
    Props,
} from "./PrivacyPolicyController";

const webTheme = createTheme(
  {palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End

export default class PrivacyPolicy extends PrivacyPolicyController {
    // Customizable Area Start
    // Customizable Area End
    
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
      const {selecteLanguage} = this.state
        return (
          <div id="main_block">
          <NavigationMenu navigation={this.props.navigation} id={""} isFooter={true} >
          <ThemeProvider theme={webTheme}>
          <Container maxWidth="xl" sx={WebStyles.mainDivStyle}>
              {selecteLanguage == "en" ?
                <>
                <Typography
                  variant="h4"
                  gutterBottom
                style={WebStyles.HeadingStyle}
                  dangerouslySetInnerHTML={{
                    __html: this.state.privacyPolicyDatadata[0]?.attributes?.title,
                  }}
                />
  
                <Typography variant="body1" paragraph>
  
                  <div dangerouslySetInnerHTML={{ __html: this.state.privacyPolicyDatadata[0]?.attributes?.description }} />
                </Typography>
  
                </>
                 :
                 <>
                 <Typography
                  variant="h4"
                  gutterBottom
                style={WebStyles.HeadingStyle}
                  dangerouslySetInnerHTML={{
                    __html: this.state.privacyPolicyDatadata[0]?.attributes?.title_ar,
                  }}
                />
  
                <Typography variant="body1" paragraph>
  
                  <div dangerouslySetInnerHTML={{ __html: this.state.privacyPolicyDatadata[0]?.attributes?.description_ar }} />
                </Typography>
                 </>
                 }
  
              </Container>
            </ThemeProvider>
          </NavigationMenu>
        </div>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const WebStyles = {
  SubHeadingStyle: {
    fontWeight: 700,
    fontSize: "20px",
    margin: "20px 0px",
  },
  ParaStyle: {
    fontSize: "16px",
    marginBottom: "20px",
    fontWeight: 400,
  },
  HeadingStyle: {
    fontWeight: 700,
    fontSize: "28px",
  },
  DividerStyle: {
    background: "#376FD0",
    height: "2px",
    marginTop: "30px",
  },
  mainDivStyle: {
    marginTop: { xs: "145px", md: "174px" },
    padding: { xs: "", lg: "0px 100px", xl: "0px 220px" },
  },
}
// Customizable Area End
