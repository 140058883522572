import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Input,
  Button,
  InputAdornment,
  Modal,
  Divider,
} from "@mui/material";
import { phoneCountrySvg, crossSvg, headerLogo } from "./assets";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import OtpInput from 'react-otp-input';
import { translate } from "../../../../packages/components/src/Translate";
// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON,
} from "./ForgotPasswordController";
// Customizable Area Start

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: "28px",
      },
      h6: {
        fontWeight: 500,
      },

      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End


export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    // Customizable Area Start

    // Customizable Area End

    return (
      // Customizable Area Start
      <div id="main_block">
        <ThemeProvider theme={theme}>
          <Box>
            <Box sx={Styles.ForgotHeader}>
              <Box
                data-test-id='header-logo'
                component="img"
                src={headerLogo.default}
                alt="headerImage"
                onClick={() => this.onPressMenuItems("Home")}
                sx={Styles.HeaderLogoStyle}
              />
            </Box>
            <Divider sx={Styles.DividerStyling} />
            <Container maxWidth="xl" sx={Styles.mainDivStyle}>
              <Typography
                variant="h4"
                gutterBottom
                sx={Styles.HeadingStyle}
                data-test-id="phone-number-title"
              >
                {translate("phoneNumberTitleFG")}
              </Typography>
              <Formik
                data-test-id="formik"
                initialValues={{ phone: this.state.phoneNumber || "" }}
                validationSchema={this.state.phoneSchema}
                onSubmit={(values, actions) => this.handleSubmit(values, actions)}
              >
                {({ errors, touched, isSubmitting, handleChange, setFieldValue }) => (
                  <Form>
                    <div>
                      <Field
                        type="tel"
                        name="phone"
                        
                        placeholder="050 123 2345"
                        data-test-id="phone-input"
                        startAdornment={
                          <InputAdornment position="start">
                            <img
                              src={phoneCountrySvg.default}
                              alt="country code"
                              style={Styles.phoneIconStyle}
                              data-test-id="phone-country-icon"
                            />
                          </InputAdornment>
                        }
                        as={Input}
                        onChange={(e: { target: { value: any } }) => {
                          this.handleFieldChangeWithTrim(e, setFieldValue, "phone", handleChange);
                        }}
                        sx={Styles.PhoneInput(errors.phone, touched.phone)}
                      />

                      <Typography sx={Styles.PhoneInputError}>
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error"
                          data-test-id="phone-error-message"
                        />
                      </Typography>
                    </div>

                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      sx={Styles.NextButton}
                      data-test-id="submit-button"
                    >
                      {translate('nextButtonFG') }
                    </Button>

                    {this.state.forgotPasswordApiResponse?.meta?.message ? (
                      <Typography data-test-id="forgot-password-response">
                        {this.state.forgotPasswordApiResponse?.meta?.message}
                      </Typography>
                    ) : (
                      this.state.formikErrors && (
                        <Typography data-test-id="formik-error" color={"#D01D1D"}>
                          {this.state.formikErrors}
                        </Typography>
                      )
                    )}
                  </Form>
                )}
              </Formik>
            </Container>
          </Box>
          <Modal
            open={this.state.isVisible}
            onClose={this.hideModal}
            aria-labelledby="modal-modal-title"
            data-test-id="modal"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Styles.modalStyle}>
              <Box sx={Styles.Wrapper}>
                <Box sx={Styles.crossWrap}>
                  <Box
                    data-test-id="close-modal"
                    onClick={() => this.hideModal()}
                    component="img"
                    src={crossSvg.default}
                    sx={Styles.crossImg}
                    alt="Cross Button"
                  />
                </Box>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={Styles.HeadingStyleOtp}
                  data-test-id="verify-title"
                >
                  {translate('verifyTitleFG')}
                </Typography>
                <Typography sx={Styles.mobileCode} data-test-id="sent-code">
                  {translate('sentCodeFG')?.replace('{phoneNumber}', this.state.phoneNumber)}
                </Typography>

                <Box sx={Styles.OtpInputWrapper}>
                  <OtpInput
                    data-test-id="OtpInputs"
                    value={this.state.otp}
                    onChange={this.handleOtpChange}
                    numInputs={4}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        data-test-id="otp-input"
                        style={{
                          border: this.getBorderStyle(index),
                          borderRadius: "16px",
                          width: "70px",
                          height: "70px",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: "35px",
                          fontWeight: "700",
                          lineHeight: "29.33px"
                        }}
                      />
                    )}
                  />
                </Box>
                {this.state.otpError && (
                  <Typography
                    color="error"
                    sx={Styles.otpError}
                    data-test-id="otp-error"
                  >
                    {this.state.otpError}
                  </Typography>
                )}

                {this.state.isTimerRunning ? (
                <Typography sx={Styles.OtpTimer} data-test-id="otp-timer">
                {translate('otpTimerFG')?.replace('{timer}', String(this.state.timer)) || 'Default timer message'}
              </Typography>
              
                ) : (
                  <Typography sx={Styles.ResendCode}>
                    {!this.state.otpError && translate('resendCodeFG')}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        data-test-id="resend-otp-button"
                        onClick={this.resendOtp}
                        sx={Styles.ClickResendCode}
                      >
                        {translate('clickResendCodeFG')}
                      </Typography>
                      <span
                        style={{
                          backgroundColor: "black",
                          height: "1px",
                          width: "122px",
                          marginLeft: "5px"
                        }}
                      ></span>
                    </div>
                  </Typography>
                )}
                <Button
                  disabled={this.state.otp.length !== 4}
                  sx={{
                    ...Styles.VerifyButton,
                    backgroundColor: this.state.otp.length === 4 ? "#1D36BB" : "gray",
                    color: this.state.otp.length === 4 ? "white" : "darkgray",
                    "&.Mui-disabled": {
                      backgroundColor: "lightgray",
                      color: "darkgray"
                    }
                  }}
                  data-test-id="verify-button"
                  onClick={this.validateOtp}
                >
                  {translate('verifyButtonFG')}
                </Button>
              </Box>
            </Box>
          </Modal>

        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const Styles = {
  modalStyle: {
    '@media (min-width:901px)': {
      width: "778px",
    },
    '@media (max-width:900px)': {
      width: "521px",
    },
    '@media (max-width:599px)': {
      width: "343px",
    },
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-54%, -50%)",
    bgcolor: "background.paper",
    borderRadius: '8px',
    marginLeft: '16px',
    marginRight: '16px',

  },
  Wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    position: 'relative',
    padding: '34.09px',
    flexWrap: 'wrap',
    '@media (max-width:900px)': {
      padding: '16px',
    },
  },
  ForgotHeader: {
    '@media (min-width:1300px)': {
      marginTop: "30px",
      marginLeft: "226px",
    },
    '@media (max-width:1300px)': {
      marginTop: "30px",
      marginLeft: "99px",
    },
    '@media (max-width:900px)': {
      margin: "25px 0px 0px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    '@media (max-width:599px)': {
      margin: "20px 0px 25px 16px",
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px 25px 16px",
    },
  },
  HeaderLogoStyle: {
    width: "auto",
    height: "auto",
    '@media (max-width:900px)': {
      width: "92px",
      height: "34px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "92px",
      height: "34px",
    },
  },
  DividerStyling: {
    marginTop: "20px",
    '@media (min-width:901px)': {
      display: "none"
    },
  },
  mainDivStyle: {
    display: "flex",
    marginTop: "150.99px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    '@media (max-width:900px)': {
      marginTop: "38px",
    },
  },
  centerContent: {
    textAlign: "center",
  },
  HeadingStyle: {
    fontWeight: 700,
    fontSize: "28px",
    marginBottom: "67px",
    fontFamily: "Poppins",
    lineHeight: "29.33px",
    textAlign: "center",
    '@media (max-width:900px)': {
      marginBottom: "60px",
    },
    '@media (max-width:599px)': {
      marginBottom: "88px",
    },
  },
  PhoneInput: (error: any, touched: any) => ({
    width: "520px",
    height: "44px",
    marginBottom: "2px",
    backgroundColor: "transparent",
    borderBottom: `2px solid ${error && touched ? "#DC2626" : "#CCF1FF"}`,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      borderBottom: '1px solid #6200EA !important',
    },
    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "44px",
    },
  }),
  PhoneInputError: {
    marginBottom: "46px",
    textAlign: "left",
    color: "#DC2626",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    '@media (max-width:599px)': {
      marginBottom: "38px",
    },
    '@media (max-width:900px)': {
  marginBottom: "58px",
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: "38px",
    },
  },
  phoneIconStyle: {
    marginRight: "8px",
  },
  NextButton: {
    width: "520px",
    height: "53px",
    fontSize: "20px",
    padding: "19.5px 236px",
    borderRadius: "6px",
    backgroundColor: "#1D36BB",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none",
    '@media (max-width:599px)': {
      width: "343px",
      height: "53px",
      padding: "26.5px 51px",
  },
    "&:hover": {
      backgroundColor: "#1D36BB",
      color: "#fff",
      boxShadow: "none",
    },
  },
forgotPass: {
    marginTop: '5px',
    marginBottom: '36px',
    maxWidth: "520px",
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
        height: "44px",
        marginBottom: "32px",
    },
},
LoginBtn: {
    maxWidth: "520px",
    height: "44px",
    fontSize: "20px",
    padding: "19.5px 236px",
    borderRadius: "6px",
    backgroundColor: "#1D36BB",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "44px",
        padding: "18px 51px",
    },
    "&:hover": {
        backgroundColor: "#1D36BB",
        color: "#fff",
        boxShadow: "none",
    },
},

mainModalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
},
otpInput: {
    border: "2px solid #1D36BB",
    borderRadius: "16px",
    width: "70px",
    height: "70px",
    marginBottom: "31px",
    marginRight: "10px",
    backgroundColor: "transparent",
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0px 0px 22px",
    fontSize: "35px",
    fontWeight: "700",
    lineHeight: "29.33px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
        width: "70px",
        height: "70px",
        marginRight: "10px",
        marginBottom: "0px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
},
VerifyButton: {
  margin:"0px -16px ",
    width: "520px",
    height: "53px",
    padding: "19.5px 236px",
    borderRadius: "6px",
    backgroundColor: "#1D36BB",
    color: "#fff",
    fontWeight: 700,
    fontSize: "20px",
    textTransform: "none",
    '@media (min-width:900px)': {
        marginBottom: "39px",
  },
    '@media (max-width:900px)': {
      width: "329px",
        height: "53px",
        padding: "19.5px 133.5px",
        marginBottom: "0px",
  },
    "&:hover": {
        backgroundColor: "#1D36BB",
        color: "#fff",
        boxShadow: "none",
    },
},
HeadingStyleOtp: {
    fontWeight: 700,
    fontSize: "28px",
    marginTop: '55px',
    marginBottom: "22px",
    fontFamily: "Poppins",
    lineHeight: "29.33px",
    textAlign: "center",
    '@media (max-width:900px)': {
      marginTop: '25px',
      marginBottom: "22px",
  },
},
crossWrap: {
    display: "flex",
    alignItems: "end",
    justifyContent: 'flex-end',
    width: "100%",
    position: "relative",
},
crossImg: {
    cursor: 'pointer',
    width: '30px',
      height:'30px',
    '@media (max-width:900px)': {
      width: '30px',
      height:'30px'
  },
},
mobileCode: {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "400",
  textAlign: "center",
  marginBottom: "37px",
  '@media (max-width:900px)': {
    marginBottom: "40px",
  },
   [theme.breakpoints.down("sm")]: {
        width: '100%',
        marginBottom: "46px"
    },
},
OtpInputWrapper: {
    display: "flex",
    justifyContent: 'center',
    marginBottom: "31px",
    alignItems: 'center',
    width: '100%',
    '@media (max-width:900px)': {
    marginBottom: "50px",
  },
    [theme.breakpoints.down("sm")]: {
        marginBottom: "44px",
    },
    "div": {
        gap: '10px',
        justifyContent: 'center',
    }
},
OtpBoxes: {
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    backgroundColor: 'red !important',
    marginRight: '10px !important'
},
ResendCode: {
    marginBottom: "37px",
    display: "flex",
    '@media (max-width:900px)': {
      marginBottom: "69px",
  },
},
OtpTimer: {
    color: '#2C2C2E',
    marginBottom: '37px',
    lineHeight: '29.33px',
    size: '16px',
    fontWeight: 700
},
ClickResendCode: {
  marginLeft: "5px",
  fontSize: "16px",
  fontFamily: "Poppins",
  lineHeight: "-1", 
  fontWeight: "700",
  textAlign: "center",
  paddingBottom: "0px", 
  marginBottom: "-4px", 
  [theme.breakpoints.down("sm")]: {},
},
otpError: {
    color: 'rgb(211, 47, 47)',
    marginBottom: '10px',
    fontSize: '14px',
},

}
// Customizable Area End
