// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as yuppp from "yup";
// Customizable Area Start
import {
  Onfido,
  OnfidoCaptureType,
} from "@onfido/react-native-sdk";
import { Platform } from "react-native";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;

  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  firstNameDefault:string,
  lastNameDefault: string,
  middleNameDefault:string,
  emailStateDefault:string,
  nationalityDefault:string,
  dateOfBirth:string,
  phoneNumber:string,
  open:boolean
  inputValue:string,
  error:string,
  otpValues: Array,
  nationalIdApiResponseData: any
  // Customizable Area End
}
interface SS {
  // Customizable Area Start

  // Customizable Area End
}
export default class KnowYourCustomerKycVerificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getVerifyIndentitycallID: string = '';
  getCreateaccountCallID: string = '';
  getreportCallID: string = '';
  // Customizable Area Start
  idNumberApiCallID: string = '';
  retrieveStatusApiCallID: string = '';
  statusCallbackApiInterval = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      firstNameDefault: "",
      lastNameDefault: "",
      middleNameDefault: "",
      emailStateDefault: "",
      nationalityDefault: "",
      dateOfBirth: "",
      phoneNumber: "",
      open:false,
      inputValue:"",
      error: "",
      otpValues: ["", ""],
      nationalIdApiResponseData: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message)//istanbul ignore next
  {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id)//istanbul ignore next
    {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null && apiRequestCallId === this.getCreateaccountCallID && !responseJson.error)//istanbul ignore next 
      {
        await setStorageData("AppicantId", responseJson.id)
        await setStorageData("ReportID", responseJson.id)
        this.onfidouserdeta()
      }
      if (apiRequestCallId != null && apiRequestCallId === this.getVerifyIndentitycallID && responseJson)//istanbul ignore next
      {
        this.startSDK(responseJson.token)
      }
      if (apiRequestCallId != null && apiRequestCallId === this.getreportCallID && responseJson)//istanbul ignore next
      {
        this.props.navigation.navigate("KnowYourCustomerKycVerificationAccount")
      }
      if (apiRequestCallId != null && apiRequestCallId === this.idNumberApiCallID && responseJson) {
        this.handleNationalIdApiResponse(responseJson)
      }
      if (apiRequestCallId != null && apiRequestCallId === this.retrieveStatusApiCallID && responseJson) { 
        this.handleRetrieveStatusApiResponse(responseJson)
      }
    }
  }

  startSDK(responseJson: string) {
    Onfido?.start({
      sdkToken: responseJson,
      flowSteps: {
        welcome: true,
        userConsent: true,
        captureDocument: {
        },
        captureFace: {
          type: OnfidoCaptureType.PHOTO
        },
        enableNFC: false
      }
    })
      ?.then((response: string) => { this.onreportApi() })
      .catch((error: string) => { })
  }

  signupValidationSchema = () => {
    return yuppp.object().shape({
      firstNameDefault: yuppp.string().required("Please, Enter your firstNameDefault!"),
      lastNameDefault: yuppp.string().required("Please, Enter your lastNameDefault!"),
      middleNameDefault: yuppp.string().required("Please, Enter your middleNameDefault!"),
      emailStateDefault: yuppp
        .string()
        .required("Please, provide your email")
        .email("Please enter valid email"),
      phoneNumber: yuppp
        .string()
        .required("Please, provide your mobile number"),
      nationalityDefault: yuppp.string().required("Please, Enter your nationalityDefault!"),
      dateOfBirth: yuppp.string().required("Please, Enter your Date of Birth!"),
    })
  }
  onNavigationGobackscreen = () => {
    
    this.props.navigation.navigate("KnowYourCustomerKycVerification")
  }

  createAccount = async (values: { firstNameDefault: string; lastNameDefault: string; middleNameDefault: string; emailStateDefault: string; nationalityDefault: string; dateOfBirth: string; phoneNumber: string; }) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const codedata = {
      first_name: values.firstNameDefault,
      last_name: values.lastNameDefault,
      middle_name: values.middleNameDefault,
      date_of_birth: values.dateOfBirth,
      nationalityDefault: values.nationalityDefault,
      phone_number: values.phoneNumber,
      email: values.emailStateDefault,
    }
    const httpBody = {
      data: codedata,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage))
    this.getCreateaccountCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
"https://datingappdemo-260453-ruby.b260453.dev.eastus.az.svc.builder.cafe//bx_block_knowyourcustomerkycverification2/applicant_create"
      
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTAPiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  onfidouserdeta = async () => {
    const UserDataValidation = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getVerifyIndentitycallID = UserDataValidation.messageId;
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getonfidoApi
    )
    const headers = {
      "Authorization":"Token token=api_sandbox.VfzFDCaYox9.rTHqR4jClR6-IHzdAQQUML5bd7PlNZ_Q"
    }
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTAPiMethod
    )
    let APPID = Platform.OS == "ios" ? "DatingAppDemo" : "com.DatingAppDemo";
    let formdata = new FormData();
    const confidoId = await getStorageData('AppicantId');
    formdata.append("applicant_id", confidoId)
    formdata.append("application_id", APPID)
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    runEngine.sendMessage(UserDataValidation.id, UserDataValidation);
  }

  onreportApi = async () => {
    const confidoId = await getStorageData('AppicantId');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getreportCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `https://datingappdemo-260453-ruby.b260453.dev.eastus.az.svc.builder.cafe/bx_block_knowyourcustomerkycverification2/retrieve_onfido_report?applicant_id=${confidoId}`
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GETApiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  // web events
  // Customizable Area Start

  async componentDidMount() {
    this.handleRemoveLocalStorageKey();
    this.handleCallbackRetrieveStatusApi();
  }

  async componentWillUnmount() {
    this.handleRetrieveStatusApiClearInterval()
  }

  handleRemoveLocalStorageKey = () => {
    localStorage.removeItem("annual_rent");
    localStorage.removeItem("duration");
    localStorage.removeItem("move_in_date");
  }

  handleCallbackRetrieveStatusApi = () => {
    if (this.state.nationalIdApiResponseData?.random?.length) {
      this.statusCallbackApiInterval = setInterval(() => {
        this.handleRetrieveStatusApi();
      }, 2000);
    }
  }

  handleRetrieveStatusApiClearInterval = () => {
    if (this.statusCallbackApiInterval) {
      clearInterval(this.statusCallbackApiInterval);
    }
  }

  handleOpen = () => {
    this.setState({open:true});
  }

  handleNationalIdApiResponse = (responseJson) => {
    if (!responseJson?.random) {
      toast.error(responseJson?.error?.message || 'Something went wrong!')
    } else {
      const randomCode = String(responseJson?.random).split('');
      this.setState({ otpValues: randomCode });
      this.setState({nationalIdApiResponseData: responseJson})
      this.handleCallbackRetrieveStatusApi();
      this.handleOpen()
    }
  }

  handleRetrieveStatusApiResponse = (responseJson) => {
    if (responseJson?.status === 'expired' || responseJson === '400-034-051' || responseJson?.error === null) {
      this.handleRetrieveStatusApiClearInterval();
      this.handleClose()
      toast.error('Session has expired!');
    } else if (responseJson?.status === 'COMPLETED') {
      this.onPressMenuItem('Openbanking')
    }
  }

  handleClose = () => {
    this.setState({open:false});
  }

  inputChangeHandler=(e)=>{
    this.setState({
      inputValue:e?.target?.value
    })
    const numericValue = e?.target?.value?.replace(/\D/g, "");
    if (numericValue?.length <= 10) {
      this.setState({ inputValue: numericValue, error: ""});
    }
  }

  validateIdType = () => {
    const { inputValue } = this.state;

    if (inputValue.length === 10) {
      if (inputValue.startsWith("1")) {
        this.setState({ error: "" });
      } else if (inputValue.startsWith("2")) {
        this.setState({ error: "" });
      } else {
        this.setState({
          error: "ID must start with '1' (National ID) or '2' (Resident ID).",
        });
      }
    } else {
      this.setState({
        error: "ID must be exactly 10 digits long.",
      });
    }
  };

  handleOpenWithValidation = () => {
    if (!this.state.error) {
      this.handleNationalIdSubmit();
    }
  };

  onPressMenuItem = (screenName:string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  };

  handleInputChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, "");
    const otpValues = [...this.state.otpValues];
    otpValues[index] = value;
    this.setState({ otpValues });
  };

  handleNationalIdSubmit = async () => {
    const loginToken = await getStorageData("login_token", true);

    const header = {
      "Content-Type": "application/json",
      token: loginToken
    };

    const payload = {
      "data": {
          "national_id": this.state.inputValue
      }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.idNumberApiCallID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_cfidentityverificationintegration/nafath/create_mfa_request?local=en"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRetrieveStatusApi = async() => {
    const loginToken = await getStorageData("login_token", true);

    const header = {
      "Content-Type": "application/json",
      token: loginToken
    };

    const payload = {
      data: {
        national_id: this.state.inputValue,
        trans_id: this.state.nationalIdApiResponseData?.trans_id, 
        random: this.state.nationalIdApiResponseData?.random
      }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    this.retrieveStatusApiCallID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_cfidentityverificationintegration/nafath/retrieve_status"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}


