import React from "react";


// Customizable Area Start

import { Box, Typography, Button, Snackbar, Alert, styled } from '@mui/material'

import Loader from "../../../../packages/components/src/Loader.web";
// Customizable Area End

import Cfopenbankingapi1Controller, {
  Props,
  configJSON,
} from "./Cfopenbankingapi1Controller";

export default class Cfopenbankingapi1 extends Cfopenbankingapi1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainBox>
        <Box className="mainWrapper">
          <Typography className="inputStyle">
            Proceed to lean connect
          </Typography>
          {this.state.sendDataMsg && (
            <Snackbar
              data-test-id="snackBarId"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              autoHideDuration={4000}
              onClose={() => this.handleSnackbarself()}
              className="snackbarStyle"
              open={this.state.isSnackbarError || this.state.isSnackbarSuccess}
            >
              <Alert severity={this.state.isSnackbarError ? 'error' : 'success'}>
                {this.state.sendDataMsg}
              </Alert>
            </Snackbar>
          )}
          <Button
            className="btnClass"
            color="inherit"
            data-test-id="btnAddExample"
            onClick={() => this.checkCustomer()}
          >
            Next
          </Button>
        </Box>
        <Loader loading={this.state.isLoader} />
      </MainBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  minHeight: "100vh",
  padding: "30px",
  background: "linear-gradient(135deg, rgba(98, 0, 238, 0.1), rgba(0, 0, 0, 0.1))",
  borderRadius: "20px",
  boxShadow: "0 6px 18px rgba(0, 0, 0, 0.15)",
  transition: "all 0.4s ease-in-out",
  "&:hover": {
    boxShadow: "0 12px 30px rgba(0, 0, 0, 0.3)",
    transform: "scale(1.03)",
  },
  "@media (max-width: 600px)": {
    padding: "15px",
  },
  "& .mainWrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    padding: "25px",
    textAlign: "center",
    borderRadius: "15px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow: "0 6px 18px rgba(0, 0, 0, 0.15)",
    },
    "@media (max-width: 600px)": {
      padding: "15px",
    }
  },
  "& .inputStyle": {
    fontSize: "24px",
    fontWeight: "600",
    color: "#333",
    marginBottom: "25px",
    letterSpacing: "1px",
    textShadow: "2px 2px 6px rgba(0, 0, 0, 0.1)",
    "@media (max-width: 600px)": {
      fontSize: "20px",
      marginBottom: "15px",
    }
  },
  "& .snackbarStyle": {
    width: "100%",
    maxWidth: "420px",
    margin: "0 auto",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    borderRadius: "10px",
    padding: "10px",
    "@media (max-width: 600px)": {
      maxWidth: "90%",
    }
  },
  "& .btnClass": {
    width: "50%",
    maxWidth: "220px",
    height: "50px",
    marginTop: "50px",
    backgroundColor: "rgb(98, 0, 238)",
    color: "#FFF",
    fontWeight: "700",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgb(84, 0, 168)",
      boxShadow: "0 6px 15px rgba(0, 0, 0, 0.25)",
      transform: "scale(1.05)",
    },
    "@media (max-width: 600px)": {
      width: "80%",
      marginTop: "30px",
    }
  }
});
// Customizable Area End
