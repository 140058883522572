import React from "react";
// Customizable Area Start
import {
    Box, Typography, Button, Container,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KnowYourCustomerKycVerificationController, {
    Props,
} from "./KnowYourCustomerKycVerificationController";
import { HeaderLogoo } from "./assets";
// Customizable Area End




export default class KnowYourCustomerKycVerificationAccount extends KnowYourCustomerKycVerificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Star
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <Container>
                <Typography
                    variant="h6"
                    data-test-id="home-logo"
                    sx={{...webStyle.logoAlign, display: 'flex', cursor: "pointer" ,alignItems: 'center', marginTop:"10px",justifyContent: { xs: 'center', sm: 'flex-start' },}}
                    onClick={() => this.onPressMenuItem("Home")}
                >
                    <img src={HeaderLogoo.default} alt="Header Logo" />
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: "60px", alignItems: 'center' }}>
                    <Box sx={{ maxWidth: '520px', height: "270px", display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleIcon sx={{ width: '80px', height: '80px', marginBottom: '40px' }} color="success" />
                        </Box>

                        <Typography sx={{ width: '90%', fontWeight: 700, fontSize: { xs: '22px', sm: '27px', md: '28px' }, textAlign: 'center',fontFamily: 'Poppins, sans-serif',}}>
                            Your Application has been submitted successfully
                        </Typography>

                        <Button variant="contained" data-test-id="go-profile" sx={{ minWidth: { xs: '100%', sm: '300px', md: '520px' }, height: '53px', bgcolor: '#1D36BB', color: 'white', borderRadius: '6px', fontWeight: 700, marginTop: { xs: '60px', sm: '20px' }, textTransform: "none" , fontFamily: 'Poppins, sans-serif',fontSize: "20px" }}
                        onClick={() => this.onPressMenuItem("CustomisableUserProfiles")}
                        >
                            Go to profile page
                        </Button>
                    </Box>
                </Box>
            </Container>
        );

        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    logoAlign: {
        "@media (max-width: 620px)": {
            justifyContent: "center",
        },
    }
}
// Customizable Area End
